//import { ElementQueries, ResizeSensor } from "css-element-queries";
declare var $: any;

//in der ngPrime Table (und TreeTable) gibt es einen Bug:
//Normalerweise fügt ngPrime im Header automatisch rechts einen 17px grossen Rand ein, falls es in der Table unten zu einem Scrollbar kommt (der ebenfalls 17px) ist.
//Damit gleicht ngPrime den Versatz, der durch den Scrollbar entsteht wieder aus.
//Das funktioniert aber nur bedingt (Stand ngPrime 7):
//wenn man z.B. im firefox mit einem zu kleinen Fenster startet hat man scrollbar + die 17px im header. Soweit ok!
//wenn man dann aber das Fenster vergrössert, so dass der Scrollbar verschwindet - aktualisiert ngPrime die 17px im header nicht.
//Hier Abhilfe:

export class TableHeaderFixer {

  // parameter: idOfTable: id der p-table (id=...)
  //            uniqueIdForScrollableBody: eine eindeutige id, die wir HIER dem child "scrollable-body" von ngPrime geben.
  //            sollte möglichst sprechend sein und Bezug zur id haben, z.B.:
  //               idOfTable = crudDataTable -> uniqueIdForScrollableBody = crudTableScroll
  fixTableHeader(idOfTable: string, uniqueIdForScrollableBody: string) {
  }

  fixTreeTableHeader(idOfTable: string, uniqueIdForScrollableBody: string) {
  }
}
